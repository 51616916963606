import React from 'react';
import {useState, useEffect} from 'react'

const useColor = (color: string) => {
    const [newColor, setNewColor] = useState(color)

    useEffect (() => {
        console.log("color", color )
        setNewColor ("#cfdfdf")
    }, [])
    return newColor
}

export default useColor