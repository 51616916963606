import React from 'react';
import './App.css';
import ButtonVip from './app/components/atoms/button/ButtonVip';
import Home from './app/pages/home/Home';
import LoginPage from './app/pages/login';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Profile from './app/pages/profile';

const App:React.FC = () :JSX.Element => {
   
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Profile />}/>
          {/* <Route path="/" element={<LoginPage />}/> */}
          {/* <Route path="/login"/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

