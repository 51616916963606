import React, { useContext } from "react";
import bird1 from "../../assets/backgroud3.jpg";
import {
  Button,
  Grid,
  ButtonProps,
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import useColor from "../../../hooks/useColor";
import style from "./ProfileNew.module.scss";
import ResponsiveAppBar from "../responsiveAppBar";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import avatar from "../../assets/anhDaiDien.jpg";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logo from "../../../logo.svg"
// import {useRef} from 'react';

import { purple } from "@mui/material/colors";
// import Pomodoro from "../pomodoro";
import { ThemeContext } from "../../context";

import connectMe from "../../assets/anhBia.jpg";
import head1 from "../../assets/head1.png";
import head2 from "../../assets/head2.png";
import head3 from "../../assets/head3.png";
import head4 from "../../assets/head4.png";
import hanhtinh from "../../assets/hanhtin2.gif";
import EasyABC from "../easyABC";
import { useWindowSize } from "../hookTest/useWindowSize";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

const Profile: React.FC = (): JSX.Element => {
  const [height, setHeight] = React.useState();
  const [value, setValue] = React.useState(0);
  const color = useColor("#fff");
  const imageRef = React.useRef<HTMLImageElement>(null);

  const refAbout = React.useRef<HTMLInputElement>(null);
  const refConnect = React.useRef<HTMLInputElement>(null);
  const refEasyABC = React.useRef<HTMLInputElement>(null);
  const themeContext = useContext(ThemeContext);

  const innerheight = window.innerHeight;
  const scrollHeight = window.scrollY;



  console.log("themeContext", themeContext);
  if (true) {
    console.log("color: ", color);
  }

  const handleClick = () => {
    refAbout.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickConnect = () => {
    refConnect.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEasyABC = () => {
    refEasyABC.current?.scrollIntoView({ behavior: "smooth" });
  };

  const aaa = useWindowSize();
  console.log("aaa: ", aaa);
  return (
    <div
      className={style.wrapper}
    
    >
      <div className={style.header}>
        {/* <button onClick={handleClick}> scroll</button> */}
        <div className={style.navbarTop} style={{position:"fixed", width:"100%", zIndex:"1000",}}>
          <ResponsiveAppBar
            handleAboutMe={handleClick}
            handleConnectMe={handleClickConnect}
            handleEasyABC={handleEasyABC}
          />
        </div>
        <div className={style.contentPage}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={5} className={style.avatarGrid}>
              <Item className={style.avatar} style={{ borderRadius: "50%", marginTop:"6vw" }}>
                <img
                  src={avatar}
                  style={{ width: "98%", height: "550px", borderRadius: "50%"  }}
                />
              </Item>
            </Grid>
            <Grid item xs={2} sm={4} md={7} style={{ marginTop:"4vw" }}>
              <div className={style.introduceMyShelf} >
                <div className={style.introduce}>Hi there, my name is</div>
                <div className={style.name}>Nghia Hoang</div>
                <div className={style.description}>
                  and I leverage Marketing and Technology to achieve sustainable
                  growth.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={style.aboutMe} ref={refAbout}>
        <div className={style.initialAboutMe}>
          <a>About Me</a>
        </div>
        <div className={style.contentAboutMe}>
          <p>
            Hello! I'm Nghia, a front-end developer passionate about ReactJS. I
            graduated from Industrial University of Ho Chi Minh City and am now
            25 years old. I have been through several ReactJS projects and some
            other technologies related to web development.
          </p>
          <p>
            I love creating interactive user interfaces and good experiences
            using ReactJS. I have worked with React components, hooks, Redux,
            React Router and other libraries to develop a powerful and flexible
            web application.
          </p>
          <p>
            I believe that skill and technology development is a constant
            process and I always aim to improve my knowledge and skills. I
            believe cooperation and experience sharing is the key to grow and
            progress together.
          </p>

          <p>
            Hope to have the opportunity to cooperate and share experiences with
            you so that we can explore and grow together with ReactJS related
            technology and skills.
          </p>
        </div>

        <div className={style.linkAboutMe}>
          <ColorButton
            variant="contained"
            className={themeContext.theme}
            onClick={themeContext.toggleTheme}
            endIcon={<GitHubIcon />}
          >
            Git Hub 1
          </ColorButton>
          <Button variant="contained" endIcon={<FacebookIcon />}>
            FaceBook
          </Button>
        </div>
      </div>

      <div className={style.myJourney}>
        <div>
          <div className={style.listImage}>
            <img
              src={connectMe}
              className={style.imagesCover}
              style={{ width: "40%", height: "40vh", borderRadius: "10px" }}
            />
          </div>
          <br />
          {/* <Pomodoro /> */}
        </div>
      </div>
      <EasyABC refEasyABC={refEasyABC} />
      <div className={style.smart}>
        {/* <img src={head3} className={style.imagesCover}/>
        <img src = {hanhtinh} ref={imageRef}
        alt="Your image"
        className={style.imageAnimation} 
        id="imageAnimation"/>
        <img src={head4} className={style.imagesCover}/> */}


      </div>

      
      <div className={style.connectMe} ref={refConnect}>
        <div className={style.bodyConnectMe}>
          <div className={style.initialConnectMe}>Let's connect me</div>
          <div>
            <div>
              <img className={style.imageConnectMe} src={connectMe} />
            </div>
            <div className={style.nameConnect}>Nghia Hoang !</div>
            <div className={style.contentConnectMe}>
              <p>
                Thanks for visiting! Send a connection request on LinkedIn or
                let's connect over a quick Zoom!
              </p>
            </div>
            <div className={style.connectMeBtn}>
              <ColorButton variant="contained" endIcon={<GitHubIcon />}>
                Git Hub
              </ColorButton>
            </div>
            <div className={style.connectMeBtn}>
              <Button variant="contained" endIcon={<FacebookIcon />}>
                FaceBook
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className={style.footer}>
        <Box sx={{ width: 500 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
            <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
          </BottomNavigation>
        </Box>
      </div>
    </div>
  );
};

export default Profile;
