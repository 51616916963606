import React from 'react';
import style from '../EasyABC.module.scss';
// import AddLinkIcon from '@mui/icons-material/AddLink';

interface ITagEasy {
    link: string;
    contentTag: string;
    icon ?: any;
    classNameTag: any;
}
const TagEasyABC : React.FC<ITagEasy> = ({link,contentTag,icon, classNameTag }) : JSX.Element=> {
    return (
        <div className={classNameTag}>
          <div className={style.linkEasy}>
            <a href="">{link}</a>
          </div>
          <div className={style.contentTagRight}>
            <p>{contentTag}</p>
            {icon}
          </div>
        </div>
    )
}

export default TagEasyABC;