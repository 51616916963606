import React from "react";

import AddLinkIcon from '@mui/icons-material/AddLink';
import TagEasyABC from "./tagEasyABC";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import style from "./EasyABC.module.scss";
interface IEasyABC {
  refEasyABC : any
}
const EasyABC: React.FC<IEasyABC> = ({refEasyABC}): JSX.Element => {
  return (
    <div className={style.easyABC} ref = {refEasyABC}>
      <div className={style.easyABCLeft}>
        <p>Easy as ABC</p>
        <p >
          No matter what you’re studying for and what your home setup is, we’ve
          got a solution for you, whether in a solo study room or a group
          session. We provide free and easy ways for you to streamline your
          study routine, no matter where you’re from, or whether you are in high
          school or university.
        </p>
        <Button title={""} className={style.customBtnCreate} >
          Create your free account
        </Button>
      </div>
      <div className={style.easyABCRight}>
        <TagEasyABC classNameTag={style.contentEasyABCRight} link={"Live-in app"} contentTag={"Solo Study"} icon = {<AddLinkIcon />}/>
        <div className={style.groupFocusBox}>
            <TagEasyABC classNameTag={style.groupFocus} link={"Live-in app"} contentTag={"Group Focus"} icon = {<LocalCafeIcon />}/> 
            <p>JOIN NOW <KeyboardDoubleArrowRightIcon/></p>
        </div>
        <TagEasyABC classNameTag={style.onDiscord} link={"On Discord"} contentTag={"Nature Sounds"} icon = {<SportsEsportsIcon />}/>
        <TagEasyABC classNameTag={style.onZoom} link={"On Zoom"} contentTag={"Study width Camera"} icon = {<VideoCameraFrontIcon />}/>

      </div>
    </div>
  );
};

export default EasyABC;
