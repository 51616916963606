import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";

const combinedReducer = combineReducers({

})

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if(action.type === 'logOut') {
        state = {} as RootState;
    }
    return combinedReducer(state, action);
}
export default rootReducer