import React, { createContext, useContext, useState } from 'react';
// Define the type for the theme
type Theme = 'light' | 'dark';

// Create the context
interface ThemeContextProps {
    theme: Theme;
    toggleTheme?: () => void
  }
type ThemeContext = {
    children: React.ReactNode
}
const ThemeContext = createContext<ThemeContextProps>({theme: "dark"});

const ThemeContextProvider = ({children} : ThemeContext) => {
    const [themeA, setThemeA] = useState<Theme>('dark')
    console.log("theme: ", themeA);

    const toggleThemeA = () => {
        console.log("here")
        setThemeA(themeA === "dark" ? "light" : 'dark') 
    }
    const value : ThemeContextProps= {
        theme: themeA,
        toggleTheme: toggleThemeA
    }


    return <ThemeContext.Provider value={value}>
        {children}
    </ThemeContext.Provider>
}

// Export the context and custom hook
export { ThemeContext, ThemeContextProvider };